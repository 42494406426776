import * as React from 'react';
import UnimplementedView from 'react-native-web/dist/modules/UnimplementedView';

function PickerIOS() {
  return <UnimplementedView />;
}

PickerIOS.Item = UnimplementedView;

export default PickerIOS;
